import { v4 as uuidv4 } from 'uuid'

export default {
  async insert (Vue, formData, idordenTrabajo, idparteTrabajo) {
    let idsubsis
    if (formData.idsubsis_idtsubsis.tipo === 'tsubsis') {
      const ordenTrabajo = await Vue.$offline.ordenTrabajo.row(idordenTrabajo)
      idsubsis = uuidv4()
      await Vue.$offline.subsis.insertSync({
        idsubsis,
        idsistema: ordenTrabajo.orden_trabajo.idsistema,
        idtsubsis: formData.idsubsis_idtsubsis.id,
        descripcion: formData.subsis_descripcion,
        estado: 1,
      })
    } else {
      idsubsis = formData.idsubsis_idtsubsis.id
    }
    await Vue.$offline.ordenTrabajoSubsis.insertSync({
      idorden_trabajo_subsis: uuidv4(),
      estado: 1,
      idorden_trabajo: idordenTrabajo,
      idsubsis,
    })
    if (formData.agregarMaterialAfectado) {
      let idordenTrabajoMatsist
      const tables = Vue.$offline.db.tables
      const materialesSistema = await Vue.$offline.db
        .select()
        .from(tables.material_sistema)
        .where(
          Vue.$offline.db.op.and(
            tables.material_sistema.idsubsis.eq(idsubsis),
            tables.material_sistema.estado.gt(0),
            tables.material_sistema.fdesinstalacion.isNull()
          )
        )
        .exec()
      for (const material of materialesSistema) {
        idordenTrabajoMatsist = uuidv4()
        await Vue.$offline.ordenTrabajoMatsist.insertSync({
          idorden_trabajo_matsist: idordenTrabajoMatsist,
          estado: 1,
          idmaterial_sistema: material.idmaterial_sistema,
          idorden_trabajo: idordenTrabajo,
        })
        await Vue.$offline.parteTrabajoMatsist.insertSync({
          idparte_trabajo_matsist: uuidv4(),
          idparte_trabajo: idparteTrabajo,
          idorden_trabajo_matsist: idordenTrabajoMatsist,
          estado: 1,
        })
      }
    }
  },
  async selectTactuacionTsistemaDeOrdenTrabajo(Vue, idordenTrabajo) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.orden_trabajo)
      .innerJoin(
        tables.tactuacion_tsistema,
        tables.orden_trabajo.idtactuacion_tsistema.eq(tables.tactuacion_tsistema.idtactuacion_tsistema)
      )
      .where(
        tables.orden_trabajo.idorden_trabajo.eq(idordenTrabajo),
      )
      .exec()
    )[0]
  },
}
