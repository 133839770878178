<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <orden-trabajo-subsis-form
          :id="null"
          :idorden-trabajo="routeParams.idorden_trabajo"
          :idparte-trabajo="routeParams.idparte_trabajo"
          :agregar-material-afectado="incluirMaterialAlParte"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import OrdenTrabajoSubsisForm from '../components/OrdenTrabajoSubsisForm'
import Data from './OrdenTrabajoSubsisAddData'

export default {
  components: {
    OrdenTrabajoSubsisForm,
  },
  mixins: [formPageMixin],
  data () {
    return {
      incluirMaterialAlParte: false,
    }
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      this.title = 'Añadir subsistema afectado'
      const tactuacionTsistema = await Data.selectTactuacionTsistemaDeOrdenTrabajo(this, this.routeParams.idorden_trabajo)
      this.incluirMaterialAlParte = tactuacionTsistema.tactuacion_tsistema.incluir_material_al_parte
    },
    async submitForm () {
      await Data.insert(this, this.formData, this.routeParams.idorden_trabajo, this.routeParams.idparte_trabajo)
      this.$appRouter.go(-1)
    },
  }
}
</script>
